
import Avatar1Img from '../assets/img/r1.jpeg';
import Avatar2Img from '../assets/img/r2.jpeg';
import Avatar3Img from '../assets/img/r3.jpeg';
import Avatar4Img from '../assets/img/r4.jpeg';
import Avatar5Img from '../assets/img/r5.jpeg';
import Avatar6Img from '../assets/img/1.jpeg';
import projImg31 from "../assets/img/31.png";
import projImg32 from "../assets/img/32.png";
import projImg33 from "../assets/img/33.png";
import projImg34 from "../assets/img/34.png";
import projImg35 from "../assets/img/35.png";
import projImg36 from "../assets/img/36.png";
import projImg37 from "../assets/img/37.png";
import projImg38 from "../assets/img/38.png";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/11.jpg";
import projImg7 from "../assets/img/12.jpg";
import projImg8 from "../assets/img/13.jpg";
import projImg9 from "../assets/img/14.jpg";
import projImg10 from "../assets/img/15.jpg";
import projImg11 from "../assets/img/16.png";
import projImg12 from "../assets/img/17.png";
import projImg13 from "../assets/img/30.jpeg";
import projImg14 from "../assets/img/19.jpg";
import projImg16 from "../assets/img/20.jpg";
import projImg15 from "../assets/img/22.png";
import projImg17 from "../assets/img/21.png";
import projImg18 from "../assets/img/23.png";
import projImg19 from "../assets/img/24.png";
import projImg20 from "../assets/img/25.png";
import projImg21 from "../assets/img/26.png";
import projImg22 from "../assets/img/27.png";
import projImg23 from "../assets/img/28.png";
import projImg24 from "../assets/img/29.png";
import projImg25 from "../assets/img/12.png";
import projImg26 from "../assets/img/13.png";
  export const newInStore = {
    title: 'New In Store Now',
    subtitle: 'Get the latest items immediately with promo prices',
    link: 'Check all',
  
    products: [
      {
        name: 'أ/عبدالله عبدالعزيز -',
        image:   <Avatar1Img />,
        sub:'من افضل الشركات في مصر والعراق احسن تعامل تم تصميم الابلكيشن بنجاح وشكرا لتعبكم وشكر خاص للمهندسه زينب ماهر لمجهودها في الابلكيشن وليس اخر تعامل بأذن الله'
      },
      {
        name: 'د/ مرفت عرابي -',
        image: <Avatar2Img />,
        sub:'من احسن الشركات في مصر تعاملت معاها اكثر من مره في الاعلانات والتصميمات ولم تقصر بشيء شركه منضبطه في المراعيد وملتزمه ب معاد التسليم شكرا لكم واتمني ان تستمرون بهذه الجوده'
      },
     
   
      
    ],
  };
  export const newInStore2 = {

  
    products: [
      {
        name: 'د/ خيريه حسن -',
        image:   <Avatar4Img />,
        sub:'من افضل واحسن شركات البرمجه تم تصميم برنامج محاسبي لعيادتي في اسبوعين فقط 🙏'
      },
      {
        name: '- Mrs/ janitilamaka',
        image: <Avatar5Img />,
        sub:'Website aplikasi dirancang dalam dua bulan, terima kasih Bu Rehab'
      },
     
   
    
      
    ],
  }
  export const newInStore3 = {

  
    products: [
        {
            name: '- Almosafer sah',
            image: <Avatar6Img />,
            sub:'One of the best companies in Egypt has created a website for us in Saudi Arabia and an excellent application. Thank you very much❤️🙏'
          },
          {
            name: ' المهندس / عبدالعزيز شريف -',
            image: <Avatar3Img />,
            sub:'شكرا استاذه رحاب علي مجهودك معنا في تصميم الويب سايت الخاص بشركه زيزو ديزاين ونتمني لكم النجاح الدائم 🙏'
          },
    
      
    ],
  }
  export const projects = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg2,
      url:'https://almashrik.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg3,
      url:'https://www.agazatravel.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg1,
      url:'https://almuayed.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg4,
      url:'https://gita.sa/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg17,
      url:'https://antonovich-design.ae/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg18,
      url:'https://zadnaeg.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg19,
      url:'https://shipboxy.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg37,
      url:'https://yummy.layalina.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg38,
      url:'https://matjarpanda.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg26,
      url:'https://almosafersah.com/'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg25,
      url:'https://socotraa.org/'
    },
  ];
  export const projects2 = [
    {
      title: "Business Startup ",
      description: "Design & Development",
      imgUrl: projImg5,
      url:'https://www.behance.net/gallery/191428453/Almosafer-Sah'
    },
    
   
   
  ];
  export const projects3 = [

    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg10,
      url:'https://www.instagram.com/where_tech/'
     
    },

    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg8,
      url:'https://www.instagram.com/where_tech/'
     
    },
  
   
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg13,
      url:'https://www.instagram.com/where_tech/'
      
    },
   
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg16,
      url:'https://www.instagram.com/where_tech/'
      
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg14,
      url:'https://www.instagram.com/where_tech/'
      
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg23,
      url:'https://mega.nz/file/9HV2iYja#QiEklhWWKdq4nyJuNov_WHe4H-IuY5srnDMmIjqxR9Q'
      
    },
  
  ];
  export const projects4 = [
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg15,
      url:'https://apps.apple.com/eg/app/tourradar-booking-adventures/id1460099102'
    },
   
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg31,
      url:'https://apps.apple.com/eg/app/%D8%A7%D9%84%D8%B3%D9%88%D9%82-%D8%A7%D9%84%D9%85%D9%81%D8%AA%D9%88%D8%AD-opensooq/id654456967?platform=messages'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg32,
      url:'https://apps.apple.com/eg/app/al-tayer-motors/id1149759021'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg33,
      url:'https://apps.apple.com/eg/app/fordeal-%D9%81%D9%88%D8%B1%D8%AF%D9%8A%D9%84-%D8%B3%D9%88%D9%82-%D8%A7%D9%84%D8%A7%D9%86%D8%AA%D8%B1%D9%86%D8%AA/id1212024125'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg34,
      url:'https://apps.apple.com/eg/app/4sale-buy-sell-everything/id435463622?platform=iphone'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg35,
      url:'https://apps.apple.com/eg/app/%D8%B9%D9%82%D8%A7%D8%B1-%D9%85%D9%88%D9%84/id1023287787'
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg36,
      url:'https://apps.apple.com/eg/app/%D8%B9%D9%82%D8%A7%D8%B1/id879992882?platform=iphone'
    },
  ];
  export const projects5 = [
    {
      title: "ERP",
      description: "Design & Development",
      imgUrl: projImg12,
      url:'https://hospital.nasserx.com/#login'
    },

    {
      title: "ERP",
      description: "Design & Development",
      imgUrl: projImg22,
      url:'https://demo.jirlie.com/'
    },
    
    {
      title: "ERP",
      description: "Design & Development",
      imgUrl: projImg24,
      url:'https://demo.kivicare.io/'
    },
    {
      title: "ERP",
      description: "Design & Development",
      imgUrl: projImg20,
      url:'https://pos.creatantech.com/login'
    },
    {
      title: "ERP",
      description: "Design & Development",
      imgUrl: projImg21,
      url:'https://demo.rajodiya.com/erpgo-saas/login'
    },
   
  ];