import React from 'react';

import image from '../assets/img/5.png'
import { useTranslation} from "react-i18next";

const Aboutp = () => {
  const { t } = useTranslation();
  return(
    <>

    <section className='min-h-[200px] m-10' id='about'  data-aos="zoom-in"
  >
    <div className=' min-h-[20px] container'>
      <div className='min-h-[500px] flex flex-col lg:flex-row items-center '  data-aos="fade-up"
  data-aos-duration="700"
  data-aos-delay="350">
        <div 
        className='flex-1 text-center lg:text-left'>
          
          <h2 className='text-[80px] capitalize m-4'>{t('who')}</h2>
        
         <p className='mb-8 max-w-[560px] text-gray-200 m-4'>
{t('who2')}
</p>
          <a href='https://www.linkedin.com/company/where-tech-s/?viewAsMember=true'><button className='btn m-2'>{t('btn')}</button></a>

        </div>
<div 
 className='-mr-[130px] z-10 '  data-aos="fade-up"
 data-aos-duration="800"
 data-aos-delay="450">
  <img src={image} className=' h-[800px] w-[800px]'></img>
</div>
      </div>

    </div>
   
  
     
     
      
  </section></>
  );
};

export default Aboutp;