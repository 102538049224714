import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/ai.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation} from "react-i18next";
export const Contact = () => {

  const { t } = useTranslation();
  return (
    <section data-aos='zoom-in' className="contact" id="Contact">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="300"className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2 data-aos="fade-up"
  data-aos-duration="500"
  data-aos-delay="250">{t('nav5')}</h2>
                <form data-aos="fade-up"
  data-aos-duration="600"
  data-aos-delay="300" >
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text"  placeholder={t("First")}  />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text"  placeholder={t("Last")}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email"  placeholder={t("Email")}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel"  placeholder={t("Phone")}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" placeholder={t("Message")}></textarea>
                      <button className="btn" type="submit"><span>{t("send")}</span></button>
                    </Col>
                  
        
                  
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
