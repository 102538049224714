import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo2.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import ReactWhatsapp from 'react-whatsapp';
import { useTranslation} from "react-i18next";
import i18next from "i18next";
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, } from "react-icons/fa"
import {
  BrowserRouter as Router
} from "react-router-dom";
import cookie from 'js-cookie'
export const NavBar = () => {
  const { t } = useTranslation();
  
    function sendEmail(message) {
        var email = 'WHERE_TECH@outlook.com';
        var subject = message.subject;
        var emailBody = 'Hi '+message.from;
        document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
    }
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
 
const languages = [
 {
  code:'ar',
  name:'العربيه',
  country_code:'sa',
  dir:'rtl'
 },
 {
  code:'en',
  name:'English',
  country_code:'gp',
  dir:'ltr'
 }
]
const GlobeIcon = ( )=>(
  <svg xmlns="http://www.w3.org/2000/svg"width='30' height='30' fill="white" class="bi bi-globe" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>
</svg>
)
const currentLanguageCode=cookie.get('il8next') || 'en' 
const currentLanguage=languages.find(l=>l.code === currentLanguageCode)
useEffect(()=>{
document.body.dir = currentLanguage.dir || 'ltr'
}, [currentLanguage])
  return (
    <div>
    <Router>
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""} data-aos="zoom-in"
                    >
        <Container >
          <Navbar.Brand className="logo" href="/">
            <img src={logo} alt="Logo" />
            <div className='text'>
            <h1>WHERE TECH</h1>
            <h6 className='gradient__text'>Software Development</h6>
          </div>
       
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav " className="nav-1">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>{t ('nav')}</Nav.Link>

              <Nav.Link href="#about" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('abou')}>{t ('nav3')}</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>{t ('nav4')}</Nav.Link>
              <Nav.Link href="#Contact" className={activeLink === 'Contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('Contact')}>{t ('nav5')}</Nav.Link>
              <Nav.Link>
                <div className="dropdown flex mx-3">
                  <button className="btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle='dropdown' aria-expanded='false'>
<GlobeIcon/>
                  </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdwonMenuButton1">
                {languages.map(({ code , name , country_code
                })=>(
<li key={country_code}>
  <button className="dropdown-item" onClick={()=>i18next.changeLanguage(code)}>
  <span className={`fi fi-${country_code} mx-2 `}></span>
    {name}
  </button>

</li>
                ))}
                </ul> </div></Nav.Link>
            </Nav>
            
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.facebook.com/profile.php?id=61556353377767&mibextid=eQY6cl"><img src={navIcon1} alt="" /></a>
                  
   <ReactWhatsapp number="+201156944712" message="Hello!!!">   <a href="">    <FaWhatsapp  className='wa text-white'>
               </FaWhatsapp></a></ReactWhatsapp>
                <a href="https://www.linkedin.com/company/where-tech-s/?viewAsMember=true"><img src={navIcon2} alt="" /></a>
                <a href="#" className="hover:text-black"> <FaEnvelope className='Email text-white hover:text-black'><button onClick={sendEmail}></button></FaEnvelope>
</a>
<a href="https://www.instagram.com/where_tech/" className="text-white"><FaInstagram className="instagram"/></a>

              </div>
             
            </span>
          </Navbar.Collapse>
        </Container>

      </Navbar>

    </Router>
    <section data-aos="zoom-in"
                   className="iconss">
        <div className="navv">
            <a data-aos="fade-up"
  data-aos-duration="700"
  data-aos-delay="450" href="https://www.facebook.com/profile.php?id=61556353377767&mibextid=eQY6cl">
            <li class="facebook">
                <span>Facebook</span>
               <FaFacebook className="facebook"/>
            </li></a>
           <li data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="400" class="Whatsapp">
           <span>Whatsapp</span>
           <ReactWhatsapp className="w-full justify-center flex" number="+201156944712" message="Hello!!!"> <FaWhatsapp  className='whatsapp'>
               </FaWhatsapp></ReactWhatsapp>
            </li>
            <a data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="450" href="https://www.linkedin.com/company/where-tech-s/?viewAsMember=true">
            <li class="linkedin">
                <span>Linkedin</span>
<FaLinkedin className="linkedin"/>
            </li></a>
          
            <a data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-delay="500" href="https://www.instagram.com/where_tech/">
            <li  class="instagram">
                <span>Instagram</span>
<FaInstagram className="instagram"/>
            </li></a>
            
            <li  data-aos="fade-up"
  data-aos-duration="1100"
  data-aos-delay="600" class="Email">
                <span>Email</span>
                <button onClick={sendEmail} className="w-full flex justify-center"><FaEnvelope className='Email'></FaEnvelope></button>
            </li>
        
        </div>
    </section>
    </div>
  )
}
