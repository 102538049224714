import React , {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.min.css'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use (LanguageDetector)
  .use(HttpApi)
  .init({
  suppotedLngs:['en', 'ar'],
    fallbackLng: "en",
    detection:{
      order: [ 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches:['cookie']

    },
    backend:{
loadPath:'/locales/{{lng}}/translation.json'
    },
   

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const loadingMarkup=(
  <div className='py-4 text-center'>
<h4>Loading...</h4>
  </div>
)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMarkup} > 
    <React.StrictMode>
    <App />
  </React.StrictMode>
  </Suspense>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
