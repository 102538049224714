

import 'react-multi-carousel/lib/styles.css';

import { useTranslation} from "react-i18next";

import Review2 from './reviews';
export const Skills = () => {
  const { t } = useTranslation();


  return (
    <section className='he'>
      <div class="container-re">
        <div class="container__left">
          <h1>{t('title_review')}</h1>
          <p>
          {t('review')}
          </p>

          <a className='text-white' href='https://www.facebook.com/profile.php?id=61556353377767&mibextid=eQY6cl'><button>{t('btn_review')}</button></a>
        </div>

        <div class="container__right">
        <Review2/>
        
          
        </div>
      </div>
    </section>
  )
}
