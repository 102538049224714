/* eslint-disable jsx-a11y/alt-text */
import React from 'react';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {newInStore, newInStore2, newInStore3} from './data';

const NewItemsSlider = () => {
  return (<>
<Swiper className='productSliderr'  grabCursor={true} breakpoints={{
  320:{
    slidesPerView:1 , 
    spaceBetween:15,
  },
  768:{
    slidesPerView:1 , 
    spaceBetween:18,
  },
}}    data-swiper-autoplay="2000"  autoplay={{ delay: 2000 }}
>
  {newInStore2.products.map((product,index)=>{
    return(
      <SwiperSlide key={index} className='max-w-[500px] '>
                  <div class="card-re relative">
          <img src={product.image.type} className='img' alt='no'></img>
            <div class="card__content">
              <span><i class="ri-double-quotes-l"></i></span>
              <div class="card__details">
                <p>
                {product.sub}                  </p>
                <h4> {product.name}</h4>
              </div>
            </div>
          </div>
        
      </SwiperSlide>
    )
  })}
</Swiper>
<Swiper className='productSliderr' 
      autoplay={{ delay: 2000 }}  data-swiper-autoplay="2000"
      grabCursor={true} breakpoints={{
  320:{
    slidesPerView:1 , 
    spaceBetween:15,
  },
  768:{
    slidesPerView:1 , 
    spaceBetween:18,
  },
}}>
  {newInStore.products.map((product,index)=>{
    return(
      <SwiperSlide key={index} className='max-w-[500px] '>
                  <div class="card-re relative">
          <img src={product.image.type} className='img' alt='no'></img>
            <div class="card__content">
              <span><i class="ri-double-quotes-l"></i></span>
              <div class="card__details">
                <p>
               {product.sub}               </p>
                <h4> {product.name}</h4>
              </div>
            </div>
          </div>
        
      </SwiperSlide>
    )
  })}
</Swiper>
<Swiper className='productSliderr'
      autoplay={{ delay: .2 }}  data-swiper-autoplay="2000"
     grabCursor={true} breakpoints={{
  320:{
    slidesPerView:1 , 
    spaceBetween:15,
  },
  768:{
    slidesPerView:1 , 
    spaceBetween:18,
  },
}}>
  {newInStore3.products.map((product,index)=>{
    return(
      <SwiperSlide key={index} className='max-w-[500px] '>
                  <div class="card-re relative">
          <img src={product.image.type} className='img' alt='no'></img>
            <div class="card__content">
              <span><i class="ri-double-quotes-l"></i></span>
              <div class="card__details">
                <p>
                {product.sub}                  </p>
                <h4> {product.name}</h4>
              </div>
            </div>
          </div>
        
      </SwiperSlide>
    )
  })}
</Swiper>

</>
  );
};

export default NewItemsSlider;