import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
import {  Nav } from "react-bootstrap";
import { useState } from "react";
import { FaPhone, FaVoicemail } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
export const Footer = () => {
  const [activeLink, setActiveLink] = useState('home');
  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
  return (
    <footer data-aos='zoom-in' className="footer ">
      <Container className="flex">
        
          <div className="text-center flex">
           
            <img data-aos="fade-up" className="w-full lg:w-5"
  data-aos-duration="500"
  data-aos-delay="250" src={logo} alt="Logo" />
           
            <div data-aos="fade-up"
  data-aos-duration="600"
  data-aos-delay="300" className='text'>
            <h1 >WHERE TECH</h1>
            <h6 className='gradient__text'>Software Development</h6>
          </div>
          </div>
          <div data-aos="fade-up"
  data-aos-duration="700"
  data-aos-delay="450" className="">

        <h4 className="m-2 p-2">Get in</h4>
        <div className="flex hover:mx-3 h"><FaPhone/><p className="px-2" >+201149717912</p></div>
        <div className="flex hover:mx-3 h"><FaPhone/><p className="px-2">009647767308305</p></div>
        <div className="flex hover:mx-3 h"><MdOutlineEmail/><p className="px-2">WHERE_TECH@outlook.com</p></div>
       
      
          </div>
         
           
      </Container>
    </footer>
  )
}
