import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

import colorSharp2 from "../assets/img/color-sharp2.png";

import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation} from "react-i18next";
import { projects, projects2, projects3, projects4, projects5 } from "./data";


  
export const Projects = () => {
const { t } = useTranslation();
 
 
  const projects6 = [

    
   
  ];
  return (
    <section data-aos="zoom-in"  className="project" id="projects">
      <Container>
        <Row>
          <Col size={16}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 data-aos="fade-up"
  data-aos-duration="500"
  data-aos-delay="250">{t('project_title')}</h2>
                <p data-aos="fade-up"
  data-aos-duration="600"
  data-aos-delay="300" className="text-[7px] lg:text-[15px]">{t('project')}</p>
                <Tab.Container data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="450"  id="projects-tabs" defaultActiveKey="one">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center cursor-pointer " id="pills-tab">
                    <Nav.Item>
                      <Nav.Link  eventKey="one"><h4  className='text-[12px] lg:text-[18px]'>{t('Website')}</h4></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="two"><h4  className='text-[12px] lg:text-[18px]'>{t('UI/UX')}</h4></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="three"> <h4  className='text-[12px] lg:text-[18px]'>{t('Graphic_Design')}</h4></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four"><h4  className='text-[12px] lg:text-[18px]'>{t('Mobile_Apps')} </h4></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five"> <h4  className='text-[12px] lg:text-[18px]'>{t('Digital_Marketing')} </h4></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sex"> <h4  className='text-[12px] lg:text-[18px]'>{t('ERP_Systems')}</h4></Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp"   >
                    <Tab.Pane   eventKey="one">
                      <Row className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  " data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="500">
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="two">
                     <Row  data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="550" className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                          projects2.map((project2, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-delay="600" eventKey="three">
    <Row  data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="550" className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                          projects3.map((project3, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project3}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-delay="600" eventKey="four">
    <Row  data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="550" className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                          projects4.map((project4, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project4}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                   
                    <Tab.Pane data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-delay="600" eventKey="sex">
    <Row  data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="550" className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                          projects5.map((project5, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project5}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane data-aos="fade-up"
  data-aos-duration="1000"
  data-aos-delay="600" eventKey="five">
    <Row  data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="550" className="w-full gird sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                          projects6.map((project6, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project6}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
