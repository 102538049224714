import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl , url }) => {
  return (
    <Col data-aos="fade-up"
    data-aos-duration="400"
    data-aos-delay="200"  size={12} sm={6} md={4} className="">
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4 className="h4 ">{title}</h4>
          <span>{description}</span>
          <div className="m-2">          <a className="text-black no-underline" href={url}><button className="text-black p-2 w-[150px] rounded-md bg-white">See</button></a></div>

        </div>
      </div>
    </Col>
  )
}
