import { useState, useEffect, useTransition } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/ai4.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation} from "react-i18next";
export const Banner = () => {
  const { t } = useTranslation();
 

 

  return (
    <div>
      
    <section  className="banner" id="home">

      <Container >
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span data-aos="fade-up"
  data-aos-duration="600"
  data-aos-delay="300" className="tagline"> {t('welcome')} </span>
                <h1 data-aos="fade-up"
  data-aos-duration="700"
  data-aos-delay="350">{t(`home2`)}</h1>
                  <p data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="400">{t('home')}</p>
                  <a href="#Contact" className="text-white no-underline"><div className="button" data-aos="fade-up"
  data-aos-duration="900"
  data-aos-delay="450">{t('bu')} <ArrowRightCircle size={25} /></div></a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img  data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500" src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section></div>
  )
}
