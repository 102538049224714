
import React from "react";

import { TfiWorld } from "react-icons/tfi";
import { GrHost } from "react-icons/gr";
import { MdLeaderboard } from "react-icons/md";

import { FaAndroid, FaLocationArrow, FaPen, FaPhoenixFramework, FaPhone, FaPhoneAlt } from "react-icons/fa";
import { useTranslation} from "react-i18next";

const projects = [





 

  
];

  const Skills2 = () => {
const { t } = useTranslation();
    return (
      <section id="skills" data-aos="zoom-in"
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 lx:grid-cols-4   place-items-center gap-4 lx:m-24">
     
      
       <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title')}</b>
              <p class="desc2">
              {t('description')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <TfiWorld/></div> 
            <div className=""><h3>{t('title')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title2')}</b>
              <p class="desc2">
              {t('description2')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <FaAndroid /></div> 
            <div className=""><h3>{t('title2')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title3')}</b>
              <p class="desc2">
              {t('description3')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <GrHost /> </div> 
            <div className=""><h3>{t('title3')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title4')}</b>
              <p class="desc2">
              {t('description4')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <FaLocationArrow/> </div> 
            <div className=""><h3>{t('title4')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title5')}</b>
              <p class="desc2">
              {t('description5')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <FaPen /></div> 
            <div className=""><h3>{t('title5')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title6')}</b>
              <p class="desc2">
              {t('description6')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <MdLeaderboard /></div> 
            <div className=""><h3>{t('title6')}</h3></div>
          </div>
        </div>
        
        
        
      </div>
         
      <div class="container-i"  data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="450">
        <div class="card" data-aos="fade-up"
  data-aos-duration="800"
  data-aos-delay="550">
          <div class="face back">
            <div class="content">
              <span class="stars"></span>
              <b class="desc">{t('title7')}</b>
              <p class="desc2">
              {t('description7')}
              </p>
            </div>
          </div>
          <div class="face front">
          <div className="fa"> <FaPhone /></div> 
            <div className=""><h3>{t('title7')}</h3></div>
          </div>
        </div>
        
        
        
      </div>

        

 

   
 

      </section>
    );
  };
  
  export default Skills2;