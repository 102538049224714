import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Aboutp from './components/about';
import Skills2 from './components/skils2';




function App() {

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <div className='gradient__bg'>
      <NavBar />
      
      <Banner />

      <Skills2/>
      <Aboutp/>
      <Projects />
      <Skills />
  
      <Contact />
      <Footer />
    </div></div>
  );
}

export default App;
